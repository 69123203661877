import axios from '@/utils/request'
/**
 * 短信推送模板
 */

// 短信模板列表
export const getSmsSysList = (params)=>{
  return axios.request({
     url: 'message/sms_sys',
     method: 'get',
     params
   })
};
// 短信模板添加
export const smsSysAdd = (data)=>{
  return axios.request({
     url: 'message/sms_sys',
     method: 'POST',
     data
   })
};
// 短信模板修改
export const smsSysEdit = (data)=>{
  return axios.request({
     url: 'message/sms_sys/'+data.id,
     method: 'PUT',
     data
   })
};
// 短信模板详情
export const getSmsSysMore = (id)=>{
  return axios.request({
     url: 'message/sms_sys/'+id,
     method: 'get'
   })
};
// 短信模板启用/禁用
export const smsSysStatus = (id)=>{
  return axios.request({
     url: 'message/sms_sys/status/'+id,
     method: 'POST'
   })
};
//短信模板删除
export const smsSysDel = (id)=>{
  return axios.request({
     url: 'message/sms_sys/'+id,
     method: 'DELETE'
   })
};
// 短信审核状态拉取
export const smsSysStatusPull = (params)=>{
  return axios.request({
     url: 'message/status/pull',
     method: 'get',
     params
   })
};
// 批量提交审核
export const smsSysAllExamine = (params)=>{
  return axios.request({
     url: 'message/all/examine',
     method: 'get',
     params
   })
};
// 获取配置信息
export const getMessageConfig = ()=>{
  return axios.request({
     url: 'message/config',
     method: 'get'
   })
};
// 更新配置信息
export const setMessageConfig = (data)=>{
  return axios.request({
    url: 'message/config/'+data.id,
    method: 'PUT',
    data
   })
};
// 短信配置启用/禁用
export const switchConfig = (data)=>{
  return axios.request({
     url: 'message/config',
     method: 'POST',
     data
   })
};
/**
 * 小程序推送模板
 */

// 小程序模板列表
export const getMiniAppList = (params)=>{
  return axios.request({
     url: 'message/miniapp_subscribe',
     method: 'get',
     params
   })
};
// 小程序类目列表
export const getMiniAppCateList = (params)=>{
  return axios.request({
     url: 'miniapp/account_cate',
     method: 'get',
     params
   })
};
// 小程序订阅模板添加
export const miniAppAdd = (data)=>{
  return axios.request({
     url: 'message/miniapp_subscribe',
     method: 'POST',
     data
   })
};
// 小程序模板修改
export const miniAppEdit = (data)=>{
  return axios.request({
     url: 'message/miniapp_subscribe/'+data.id,
     method: 'PUT',
     data
   })
};
// 小程序模板详情
export const getMiniAppMore = (id)=>{
  return axios.request({
     url: 'message/miniapp_subscribe/'+id,
     method: 'get'
   })
};
// 小程序模板启用/禁用
export const miniAppStatus = (id)=>{
  return axios.request({
     url: 'message/miniapp_subscribe/status/'+id,
     method: 'POST'
   })
};
//小程序模板删除
export const miniAppSysDel = (id)=>{
  return axios.request({
     url: 'message/miniapp_subscribe/'+id,
     method: 'DELETE'
   })
};


/**
 * 公众号推送模板
 */

// 公众号模板列表
export const getWechatList = (params)=>{
  return axios.request({
     url: 'message/wechat_template',
     method: 'get',
     params
   })
};
// 公众号模板类目列表
export const getWechatCateList = (params)=>{
  return axios.request({
     url: 'message/wechat_template/cate',
     method: 'get',
     params
   })
};
// 公众号模板添加
export const wechatAdd = (data)=>{
  return axios.request({
     url: 'message/wechat_template',
     method: 'POST',
     data
   })
};
// 公众号模板修改
export const wechatEdit = (data)=>{
  return axios.request({
     url: 'message/wechat_template/'+data.id,
     method: 'PUT',
     data
   })
};
// 公众号模板详情
export const getWechatMore = (id)=>{
  return axios.request({
     url: 'message/wechat_template/'+id,
     method: 'get'
   })
};
// 公众号模板启用/禁用
export const wechatStatus = (id)=>{
  return axios.request({
     url: 'message/wechat_template/status/'+id,
     method: 'POST'
   })
};
//公众号模板删除
export const wechatDel = (id)=>{
  return axios.request({
     url: 'message/wechat_template/'+id,
     method: 'DELETE'
   })
};
// 充值记录i
export const getRechargeRecordList = (params)=>{
  return axios.request({
     url: 'message/rechargeRecord',
     method: 'get',
     params
   })
};
// 发送记录
export const getSendRecordList = (params)=>{
  return axios.request({
     url: 'message/sendRecord',
     method: 'get',
     params
   })
};
// 概览
export const getMessageStatistics = (params)=>{
  return axios.request({
     url: 'message/statistics',
     method: 'get',
     params
   })
};